import React, { Component } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import TinyMce from 'react-tinymce'
import _ from 'lodash'
import { OembedApi } from 'trill-api-admin-client'
import { v4 as uuidv4 } from 'uuid'

import LogLevel from '../LogLevel'

import './ArticleDescriptionEditor.css'

const logger = LogLevel.getLogger('ArticleDescriptionEditor')
const oembedApi = new OembedApi()

const warningByteLength = 23068672 // 22MB
const imageCaptionTextDefault = '出典元の情報、または画像の説明'
const emptyEditorContentErrorMessage = '本文を入力してください'
const externalImageUrlRegexString = `<img([^>]+)src="(?!(data:|blob:|${process.env.REACT_APP_TRILL_CLOUDFRONT_IMAGE_URL}))([^"]+)"([^>]+)>`
const externalImageUrlErrorMessage =
  '画像URLの内容に誤りがあります。編集画面の本文内を右クリックし、画像を挿入からアップロードし直してください。'

const propTypes = {
  /**
   * 記事本文
   */
  description: PropTypes.string,

  /**
   * 初期処理完了後に呼び出す外部関数
   *
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {Object} data.editor - 記事本文エディター（TinyMCE）のインスタンス
   */
  onInit: PropTypes.func,

  /**
   * 概要を変更した時に呼び出す外部関数
   *
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {string} data.description - 変更された記事本文
   */
  onChange: PropTypes.func,

  /**
   * 概要を変更した時に呼び出す外部関数
   *
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {Blob} data.blob - 記事本文に挿入した画像データ
   * @param {function} data.success - アップロードの成功時にコールする関数
   * @param {function} data.failure - アップロードの失敗時にコールする関数
   */
  onImageUpload: PropTypes.func,
}

const defaultProps = {
  description: '',
  isTinyMceScriptLoaded: false,
}

class ArticleDescriptionEditor extends Component {
  state = {
    isBusy: false,
  }

  /**
   * 記事本文エディター（TinyMCE）の初期化オプション
   */
  config = {
    table_default_attributes: {
      class: 'trill-article-table',
      border: '1',
    },

    language_url: '/scripts/tinymce/langs/ja.js',

    inline: true,
    theme: 'inlite',

    plugins: ['link image media lists hr', 'autolink imagetools paste', 'contextmenu preview visualblocks code table'],

    // This option allows you to disable TinyMCE's default paste filters when set to false.
    paste_enable_default_filters: false,

    // SNS 埋め込みで iframe に script を埋め込むため true に設定
    allow_script_urls: true,

    // iframe 埋め込み表示時 (Youtube) に width と height が TinyMCE で削除されてしまうため設定
    // script is not valid element by default
    // https://www.tiny.cloud/docs/configure/content-filtering/#valid_elements
    extended_valid_elements: 'span[!*],script[async|defer|src]',

    image_caption: false,
    image_dimensions: false,
    paste_data_images: true,

    media_alt_source: false,
    media_poster: false,

    // FIXME: quicklink のツールチップが日本語化されてない
    selection_toolbar: [
      'quicklink',
      'linkButton linkButtonAccent',
      '|',
      'bold italic',
      '|',
      'h2 h3 h4',
      '|',
      'blockquote cite',
      '|',
      'bullist numlist',
      '|',
      'snsEmbedTwitter',
      'snsEmbedFacebook',
      'snsEmbedInstagram',
      'snsEmbedPinterest',
    ].join(' '),

    insert_toolbar: 'quickimage media | blockquote | bullist numlist | hr | articleInternalLink | table',

    contextmenu: 'visualblocks pastetext code cell column row downloadimage',
    contextmenu_never_use_native: true,

    formats: {
      h1: { block: 'h1', classes: 'heading' },
      h2: { block: 'h2', classes: 'heading' },
      h3: { block: 'h3', classes: 'heading' },
      h4: { block: 'h4', classes: 'heading' },
      h5: { block: 'h5', classes: 'heading' },
      h6: { block: 'h6', classes: 'heading' },
      // FIXME: 引用の中にリストも入れられた方が良い？(新デザイン仕様では定義されてないが)
      // TODO: リストの中に引用が入るのは防ぎたい
      blockquote: { block: 'blockquote' },
      cite: { inline: 'cite' },
      blockButton: { selector: 'a', classes: 'button button--block' },
      embed: { block: 'p' },
    },

    content_css: [
      // TRILL 記事本文の表示に必要なスタイル
      process.env.REACT_APP_TRILL_DESCRIPTION_STYLE_URL,
    ],
  }

  /**
   * 記事本文エディター（TinyMCE）のインスタンス
   */
  editor = null

  /**
   * 記事本文エディターの HTML 出力に必要な記事のタイトル
   */
  title = ''

  /**
   * 記事本文エディターの HTML 出力に必要な記事の概要
   */
  summary = ''

  /**
   * 記事本文
   */
  description = ''

  /**
   * 記事本文エディターで（１回でも）コンテンツの編集が行われたかどうか
   */
  isDirty = false

  /**
   * 記事本文エディターからフォーカスが（１回でも）外れた事があるかどうか
   */
  isBlurred = false

  /**
   * 容量警告を表示しているかどうか
   */
  isWarningByteLength = false

  /**
   * 記事本文に対するエラー
   */
  editorError = null

  constructor(props) {
    super(props)

    this.description = this.props.description

    // 記事本文の初期値 (記事本文変更判定で使用)
    this.initialDescription = this.props.description
    // 記事本文に変更があったかどうか
    this.descriptionModified = false

    this.config.setup = editor => {
      editor.on('Dirty', () => {
        logger.debug('TinyMCE events (Dirty)')
        this.isDirty = true
      })

      editor.on('Blur', () => {
        logger.debug('TinyMCE events (Blur)')
        this.isBlurred = true
        const editorContent = editor.getContent()
        // フォーカスが外れたときに記事本文情報を通知
        this.handleChange(editorContent, this.editorError)
      })

      editor.on('Change Blur KeyUp', () => {
        logger.debug('TinyMCE events (Change/Blur/KeyUp)')

        const editorContent = editor.getContent()
        const isExternalImageUrl = editorContent && editorContent.match(new RegExp(externalImageUrlRegexString, 'gmi'))
        const editorErrorMessage = _.get(this.editorError, 'message', null)

        // すでに本文エディターで編集を行った後なら、本文が必ず入力される様にエラーメッセージを表示
        if (this.isDirty && this.isBlurred) {
          if (!_.isEqual(editorErrorMessage, emptyEditorContentErrorMessage) && !editorContent) {
            // editorError が null かつ 本文が空の場合 = エラー文言を表示する
            this.editorError = new Error(emptyEditorContentErrorMessage)
            this.handleChange(editorContent, this.editorError)
          } else if (!_.isEqual(editorErrorMessage, externalImageUrlErrorMessage) && isExternalImageUrl) {
            this.editorError = new Error(externalImageUrlErrorMessage)
            this.handleChange(editorContent, this.editorError)
          } else if (editorErrorMessage && editorContent && !isExternalImageUrl) {
            // editorError が存在する (エラー文言を表示している) かつ 本文が存在する場合 = エラー文言を非表示にする
            this.editorError = null
            this.handleChange(editorContent, this.editorError)
          }
        } else if (this.isDirty && !_.isEqual(this.initialDescription, this.description) && !this.descriptionModified) {
          // 記事本文の初期値から変更があった場合 かつ this.descriptionModified が false のときのみ通知を行う
          this.descriptionModified = true
          this.handleChange(editorContent, this.editorError)
        } else if (this.isDirty && _.isEqual(this.initialDescription, this.description) && this.descriptionModified) {
          // 記事本文の初期値から変更してから初期値に戻った場合 かつ this.descriptionModified が true のときのみ通知を行う
          this.descriptionModified = false
          this.handleChange(editorContent, this.editorError)
        }
      })

      const handleCheckImageSize = image => {
        if (_.isNil(image)) {
          return
        }

        const imageLoadHandler = () => {
          // 画像の表示サイズが指定されてない場合は、画像の元の大きさで指定
          if (_.isNil(image.getAttribute('width')) || _.isNil(image.getAttribute('height'))) {
            const imageNodeCheckSizeId = image.getAttribute('data-check-size-id')
            const currentImage =
              document.querySelector(`.ArticleDescriptionEditor img[data-check-size-id="${imageNodeCheckSizeId}"]`) ||
              document.querySelector(`.ArticleDescriptionEditor img[src*="${image.src}"]`)

            if (!_.isNil(currentImage)) {
              currentImage.setAttribute('width', image.naturalWidth)
              currentImage.setAttribute('height', image.naturalHeight)
              currentImage.removeAttribute('data-check-size-id')
            }
          }

          // 画像のサイズが小さい時は警告メッセージを表示
          if (image.naturalWidth < 640) {
            editor.notificationManager.open({
              text: '画像の横幅のサイズは推奨 2304 px 以上、最低 640 px 以上にしてください。',
              type: 'warning',
            })
          }

          image.removeEventListener('load', imageLoadHandler)
        }

        // サイズチェックは一度のみ行う
        if (
          _.isNil(image.getAttribute('data-size-checked')) ||
          _.isNil(image.getAttribute('width')) ||
          _.isNil(image.getAttribute('height'))
        ) {
          const imageNodeCheckSizeId = uuidv4()

          image.setAttribute('data-check-size-id', imageNodeCheckSizeId)
          image.addEventListener('load', imageLoadHandler)
          image.setAttribute('data-size-checked', true)
        }
      }

      editor.on('PastePreProcess', event => {
        let newContent = event.content

        /**
         * The span tag will be removed when content is added to the editor.
         * So we will need to handle to retain bold and italic properties.
         */
        const handleConvertSpanStyleToValidElement = () => {
          newContent = newContent.replace(/<span([^>]*)style="([^"]*)">([^<]+)<\/span>/gim, (match, p1, p2) => {
            let newValue = match

            if (p2.match(/font-style:italic/g)) {
              newValue = `<em>${newValue}</em>`
            }

            if (p2.match(/font-weight:(700|bold)/g)) {
              newValue = `<strong>${newValue}</strong>`
            }

            return newValue
          })
        }

        /**
         * When pasting, the image tag can be inside the tag of p, ul, ol, bold, italic,...
         *  - When the image is in a p tag (it contains only an image) or a figure, the parsing is fine.
         *  - But when it is in other tags like ul, ol, bold, italic or in a p tag containing text, the parse will lose the image or fail.
         * => Therefore, we will need to separate the image tag into a new block, inside p tag
         */
        const handleConvertSpanImageToBlockElement = () => {
          newContent = newContent.replace(
            /<span([^>]*)>(.*?)<img(.*?)src="(.*?)"([^>]*)>(.*?)([^<]+)<\/span>/gim,
            match => {
              return `<p>${match}</p>`
            },
          )
        }

        handleConvertSpanStyleToValidElement()
        handleConvertSpanImageToBlockElement()

        event.content = newContent
      })

      editor.on('PastePostProcess', event => {
        const handleChangeNodeName = (oldNode, newNodeName, newNodeInnerHtml, attributes = {}) => {
          const parentNode = oldNode.parentNode
          const newNode = document.createElement(newNodeName)

          if (!_.isNil(attributes)) {
            _.keys(attributes).forEach(attributeKey => newNode.setAttribute(attributeKey, attributes[attributeKey]))
          }

          newNode.innerHTML = newNodeInnerHtml
          parentNode.insertBefore(newNode, oldNode)
          parentNode.removeChild(oldNode)

          return newNode
        }

        const createImageObjectURL = async imageNode => {
          const imageNodeObjectUrlId = uuidv4()
          imageNode.setAttribute('data-object-url-id', imageNodeObjectUrlId)

          try {
            const blob = await fetch(imageNode.getAttribute('src')).then(r => r.blob())
            const objectUrl = URL.createObjectURL(blob)
            const currentImage =
              document.querySelector(`.ArticleDescriptionEditor img[data-object-url-id="${imageNodeObjectUrlId}"]`) ||
              document.querySelector(`.ArticleDescriptionEditor img[src*="${imageNode.src}"]`)

            if (currentImage) {
              currentImage.setAttribute('src', objectUrl)
              currentImage.setAttribute('data-mce-src', objectUrl)
              currentImage.removeAttribute('data-object-url-id')
            }
          } catch (error) {
            logger.error('create image object URL error', error)
          }
        }

        /**
         * Reformat the node, being like the one supported by Trill
         */
        const handleReformatNode = node => {
          if (!node) {
            return
          }

          /**
           * Remove attributes not held by Trill.
           * If you intentionally keep it, it will also be deleted when content is saved
           */
          _.each(['id', 'class', 'dir', 'aria-level', 'style', 'align'], attribute => {
            if (node.hasAttribute(attribute)) {
              node.removeAttribute(attribute)
            }
          })

          const nodeName = node.nodeName
          const parentNode = node.parentNode

          /**
           * Reformat the children of node
           */
          const handleReformatNodeChildren = () => {
            _.each(node.children, nodeChildren => {
              handleReformatNode(nodeChildren)
            })
          }

          /**
           * Remove redundant blank p tag (Keep image tag format)
           */
          const handleRemovePNodeChildrenRedundant = () => {
            node.innerHTML = node.innerHTML
              .replace(/<p([^>]*)>(.*?)<\/p>/gm, (match, p1, p2) => p2)
              .replace(/<img(.*?)src="(.*?)"([^>]*)>/gim, match => {
                return `<figure class="image" contenteditable="false">${match}<figcaption contenteditable="true">${imageCaptionTextDefault}</figcaption></figure>`
              })
          }

          /**
           * Set the figure attributes just like when creating a new figure to match the figure format that Trill supports
           */
          const handleSetTrillFigureAttributes = () => {
            node.className = 'image'
            node.setAttribute('contenteditable', false)
          }

          /**
           * Set the table attributes just like when creating a new table to match the table format that Trill supports
           */
          const handleSetTrillTableAttributes = () => {
            node.className = 'trill-article-table'
            node.border = '1'
            node.style.borderCollapse = 'collapse'
            node.style.width = '100%'
          }

          /**
           * Set the table cells attributes just like when creating a new table
           */
          const handleSetTrillTableCellsAttributes = () => {
            node.style.width = `${100 / parentNode.children.length}%`
          }

          if (nodeName.match(/^h[1-6]$/gi)) {
            /**
             * For heading tags, Trill only supports text content
             * Therefore, we just need to set the content as text
             */
            node.innerHTML = node.innerText
          } else if (nodeName.match(/^p$/gi)) {
            handleReformatNodeChildren()
          } else if (nodeName.match(/^a$/gi)) {
            handleReformatNodeChildren()
          } else if (nodeName.match(/^strong|em$/gi)) {
            handleReformatNodeChildren()

            /**
             * To eliminate the case where elements are nested and have no content
             */
            if (!node.innerText.trim()) {
              node.innerHTML = '<br />'
            }
          } else if (nodeName.match(/^br|hr$/gi)) {
            /**
             * For the break line tag, we will remove all unnecessary attributes, just leave the content blank
             */
            node.innerHTML = ''
          } else if (nodeName.match(/^ol|ul$/gi)) {
            handleReformatNodeChildren()
          } else if (nodeName.match(/^li$/gi)) {
            _.each(node.children, nodeChildren => {
              handleReformatNode(nodeChildren)

              /**
               * Trill only supports line break, bold, italic, and links in li tags.
               * Therefore, for the other tags we will need to remove them.
               */
              if (!nodeChildren.nodeName.match(/^br|b|strong|i|em|a$/gi)) {
                nodeChildren.outerHTML = nodeChildren.innerHTML
              }
            })
          } else if (nodeName.match(/^figure$/gi)) {
            handleReformatNodeChildren()
            handleSetTrillFigureAttributes()
          } else if (nodeName.match(/^img$/gi)) {
            /**
             * Create object url from original url
             */
            const imageUrl = node.getAttribute('src')
            if (imageUrl && !imageUrl.match(/^(blob|data):/gi)) {
              createImageObjectURL(node)
            }

            // Check image size
            handleCheckImageSize(node)

            /**
             * Separate the image tag into a new block, inside figure tag
             */
            if (!(parentNode.nodeName.match(/^figure$/gi) && parentNode.childNodes.length === 2)) {
              node = handleChangeNodeName(
                node,
                'figure',
                `${node.outerHTML}<figcaption contenteditable="true">${imageCaptionTextDefault}</figcaption>`,
                { class: 'image', contenteditable: false },
              )
            }
          } else if (nodeName.match(/^figcaption$/gi)) {
            handleReformatNodeChildren()
          } else if (nodeName.match(/^table$/gi)) {
            handleReformatNodeChildren()
            handleSetTrillTableAttributes()
          } else if (nodeName.match(/^colgroup|col$/gi)) {
            handleReformatNodeChildren()
          } else if (nodeName.match(/^thead|tbody|tfoot|tr$/gi)) {
            handleReformatNodeChildren()
          } else if (nodeName.match(/^th|td$/gi)) {
            handleReformatNodeChildren()
            handleSetTrillTableCellsAttributes()
          } else {
            handleReformatNodeChildren()

            /**
             * For other, which Trill unsupported tags, we will convert to p tag.
             * The convert can result in empty tags, so we'll need an extra step to remove these unnecessary, redundant tags.
             */
            node = handleChangeNodeName(node, 'p', node.innerHTML)
            handleRemovePNodeChildrenRedundant()
          }
        }

        /**
         * Reformat the node, before they are added
         */
        const childrenNodes = event.node.children
        _.each(childrenNodes, nodeChildren => {
          handleReformatNode(nodeChildren)
        })

        /**
         * If the last element is an image, we break the line with a new line
         */
        const childrenNodeSize = childrenNodes.length
        if (childrenNodeSize && childrenNodes[childrenNodeSize - 1].nodeName.match(/^figure$/gi)) {
          event.node.innerHTML = `${event.node.innerHTML}<p></p>`
        }
      })

      // NodeChange をハンドルする
      editor.on('NodeChange', event => {
        if (this.isDirty) {
          logger.debug('TinyMCE events (NodeChange) - editor content props handle')

          const editorContent = editor.getContent()

          const byteLength = Buffer.byteLength(editorContent)
          this.handleByteLengthWarning(byteLength > warningByteLength)
        }
      })

      // TinyMCE のコンテンツ選択時のツールバーが、編集できない要素を選択しても表示される不具合の対応
      // TODO: TinyMCE のコアな部分を修正して本家の GitHub にプルリクあげる
      editor.on('NodeChange', event => {
        const selectionToolbar = document.querySelector('.mce-tinymce-inline.mce-floatpanel')

        if (!_.isNil(selectionToolbar)) {
          logger.debug('TinyMCE events (NodeChange) - selection toolbar', event.element, event.parents)

          // Setup Selection Toolbar
          if (
            event.element.getAttribute('contenteditable') === 'false' ||
            event.element.classList.contains('mce-content-body')
          ) {
            selectionToolbar.dataset.trillMceSelectionToolbarDisabled = true
          } else {
            selectionToolbar.dataset.trillMceSelectionToolbarDisabled = false
          }

          // Setup Edit Image Toolbar
          const editImageToolbarButton = selectionToolbar.querySelector(
            '.mce-toolbar .mce-btn-group .mce-btn[role="button"][aria-label="Edit image"]',
          )

          if (editImageToolbarButton && editImageToolbarButton.getAttribute('is-setup') !== 'true') {
            editImageToolbarButton.addEventListener('click', () => {
              const downloadImage = url => {
                const filename = url.split('/').pop()
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
              let numOfInterval = 0
              const intervalID = setInterval(() => {
                numOfInterval += 1

                const editImagePanels = document.querySelectorAll(
                  '.mce-container.mce-panel.mce-window[aria-label="Edit image"]',
                )
                const editImagePanel = editImagePanels[editImagePanels.length - 1]

                if (editImagePanel && editImagePanel.getAttribute('is-setup') !== 'true') {
                  const saveButton = editImagePanel.querySelector(
                    '.mce-container.mce-panel.mce-foot .mce-container-body .mce-widget.mce-btn[role=button]',
                  )
                  saveButton.querySelector('button .mce-txt').innerText = '記事反映'
                  saveButton.style.width = '72px'
                  saveButton.style.left = `${saveButton.offsetLeft - 24}px`

                  const downloadButton = document.createElement('div')
                  downloadButton.style.width = '94px'
                  downloadButton.style.height = '28px'
                  downloadButton.style.top = '10px'
                  downloadButton.style.left = `${saveButton.offsetLeft - 102}px`
                  downloadButton.className = 'mce-btn mce-primary mce-abs-layout-item mce-btn-has-text'
                  downloadButton.innerHTML = `
                    <button role="presentation" type="button" tabindex="-1" style="height: 100%; width: 100%; padding: 0;">
                      <span class="mce-txt">
                        ダウンロード
                      </span>
                    </button>
                  `
                  downloadButton.addEventListener('click', () => {
                    const imageUrl = editImagePanel
                      .querySelector('.mce-container-body.mce-window-body .mce-imagepanel img')
                      .getAttribute('src')

                    downloadImage(imageUrl)
                  })

                  saveButton.insertAdjacentElement('afterend', downloadButton)
                  editImagePanel.setAttribute('is-setup', 'true')
                  clearInterval(intervalID)
                }

                if (numOfInterval > 100) {
                  clearInterval(intervalID)
                }
              }, 200)
            })
            editImageToolbarButton.setAttribute('is-setup', 'true')
          }
        }
      })

      /**
       * --------------------------------------------------------------------
       * This logic has been implemented in function handleReformatNode above
       * --------------------------------------------------------------------
       * editor.on('PastePostProcess', data => {
       *   const currentNode = data.node
       *   const childImage = _.find(currentNode.children, child => _.isEqual(child.nodeName.toLowerCase(), 'img'))
       *
       *   if (_.isNil(childImage)) {
       *     return
       *   }
       *
       *   currentNode.innerHTML = `<p>${currentNode.innerHTML}</p>`
       * })
       *  */

      // 画像コンテンツを TRILL 仕様に合わせて制御
      editor.on('NodeChange', event => {
        const findChildImage = element =>
          _.find(element.children, child => _.isEqual(child.nodeName.toLowerCase(), 'img'))
        const isValidFigureNode = figureNode => !_.isNil(findChildImage(figureNode))

        const currentNode = event.element
        const currentNodeName = currentNode.nodeName.toLowerCase()
        // figcaption に link をつけた場合、a タグに囲まれて階層が深くなるので 2 階層上まで figure を検索して取得
        const parentFigure = this.findParentNode(currentNode, 'figure', 2)
        const childImage = findChildImage(event.element)
        const childCaption = _.find(event.element.children, child =>
          _.isEqual(child.nodeName.toLowerCase(), 'figcaption'),
        )

        // figure タグの contenteditable 要素を操作
        if (!_.isNil(parentFigure)) {
          if (!isValidFigureNode(parentFigure)) {
            parentFigure.remove()
            return
          }

          // img or figcaption を選択してもツールバーが出ないため true に設定
          parentFigure.setAttribute('contenteditable', true)
        } else if (_.isEqual(currentNodeName, 'figure')) {
          if (!isValidFigureNode(currentNode)) {
            currentNode.remove()
            return
          }

          // true のままだと figure を選択してもフォーカスが外れないため false に設定
          currentNode.setAttribute('contenteditable', false)
        }

        if (_.isNil(childImage)) {
          // 画像以外の要素が変更されたケースでは何もしない
          return
        }

        /**
         * When uploading an image in a ul, ol, blockquote,... tags, the image will be added to a new tag after that tag.
         * Because if the image is in these tags, you will lose it when TrillDescription.parse.
         * So please exit so you don't lose it.
         */
        const currentParentsNode = event.parents
        const currentLastParentNode = currentParentsNode[currentParentsNode.length - 1]
        if (!_.isNil(currentLastParentNode) && !currentLastParentNode.nodeName.match(/^figure$/gi)) {
          const figureNode = document.createElement('figure')
          figureNode.className = 'image'
          figureNode.setAttribute('contenteditable', false)
          figureNode.innerHTML = `${childImage.outerHTML}<figcaption contenteditable="true">${
            _.isNil(childCaption) ? imageCaptionTextDefault : childCaption.innerHTML
          }</figcaption>`
          currentLastParentNode.parentNode.insertBefore(figureNode, currentLastParentNode.nextSibling)
          currentNode.remove()
        }

        logger.debug('TinyMCE events (NodeChange) - figure image', event.element, event.parents, currentNodeName)

        // 画像に対する処理 (figure タグ内の img タグに対する contenteditable がデフォルトでは設定されないため true に設定)
        childImage.setAttribute('contenteditable', true)

        // キャプションに対する処理
        if (!_.isNil(childCaption)) {
          childCaption.setAttribute('contenteditable', true)

          // キャプション文字列のローカライズ対応
          // TODO: TinyMCE のコアな部分を修正して本家の GitHub にプルリクあげる
          if (_.isEqual(childCaption.innerHTML, 'Caption')) {
            childCaption.innerHTML = imageCaptionTextDefault
          }
        }

        // TODO: figcaption 中ではヘッダーと引用を設定できない様にする

        // サイズチェックは一度のみ行う
        handleCheckImageSize(childImage)
      })

      // youtube の埋め込み時に rel=0 を付与して関連動画には同じチャンネル内の動画を表示する
      editor.on('NodeChange', event => {
        if (event.element.children.length === 0) {
          return
        }
        const childIframe = _.find(event.element.children[0].children, child =>
          _.isEqual(child.nodeName.toLowerCase(), 'iframe'),
        )

        const currentNode = event.element
        const currentNodeName = currentNode.nodeName.toLowerCase()

        if (!_.isEqual(currentNodeName, 'p') || _.isNil(childIframe)) {
          // iframe 以外の要素が変更されたケースでは何もしない
          return
        }

        const childIframeSrc = childIframe.getAttribute('src')
        const youtubeEmbedUrlPattern = /^(http:|https:|)\/\/www\.youtube\.com\/embed\/([^?]+)(\?.+)?/

        if (!youtubeEmbedUrlPattern.test(childIframeSrc)) {
          // youtube埋め込みタグ以外の要素が変更されたケースでは何もしない
          return
        }

        logger.debug(
          'TinyMCE events (NodeChange) - p span youtube iframe',
          event.element,
          event.parents,
          currentNodeName,
        )

        // 一度のみ行う
        if (_.isNil(childIframe.getAttribute('data-youtube-rel-checked'))) {
          if (!_.isNil(childIframeSrc) && childIframeSrc.indexOf('rel=') === -1) {
            childIframe.setAttribute('src', `${childIframeSrc}${childIframeSrc.indexOf('?') === -1 ? '?' : '&'}rel=0`)
          }
          childIframe.setAttribute('data-youtube-rel-checked', true)
        }
      })

      const replaceWithIframeEmbed = (targetNode, embedHtml) => {
        const parentNode = targetNode.parentNode

        if (parentNode.className !== 'trill-description mce-content-body') {
          parentNode.setAttribute('contenteditable', false)
        }

        const iframe = document.createElement('iframe')
        iframe.width = '100%'
        iframe.src = `javascript: '${embedHtml.replace(/'/g, '"')}'`

        // 読み込み完了後に iframe 内のコンテンツの高さを取得して iframe の高さを変更
        iframe.onload = () => {
          const iframeDoc = iframe.contentWindow.document
          const margin = 80
          const contentHeight = iframeDoc.body.scrollHeight + margin

          logger.debug(`iframe onload content height #${contentHeight} px`)

          iframeDoc.body.setAttribute('style', 'overflow: auto; height: 100%')

          // FIXME: iframe の高さを設定 (iframe の高さが fit せずスクロールが出てしまう...)
          iframe.height = contentHeight
        }

        iframe.setAttribute('style', 'border: none')

        // a タグと iframe を差し替える
        parentNode.replaceChild(iframe, targetNode)
      }

      const getPinterestEmbedHtml = url => {
        // Pinterest の場合 oEmbedAPI からの取得ではなく、コードを生成して返却
        const ephoxEmbedIriData = url.match(/pinterest.\w*\/+(\w*)\/+(\w*)/)

        if (!ephoxEmbedIriData) {
          logger.warn(`Invalid pinterest ephox embed iri data ${ephoxEmbedIriData}`)
          return
        }

        // @see https://developers.pinterest.com/docs/widgets/pin-widget/
        const isPinWidget = _.isEqual(ephoxEmbedIriData[1], 'pin')

        // @see https://developers.pinterest.com/docs/widgets/board-widget/
        const isBoardWidget = !_.isEmpty(ephoxEmbedIriData[2])

        // Widget の種類
        let pinterestWidgetValue

        if (isPinWidget) {
          // ephoxEmbedIri に pin があれば PinWidget
          pinterestWidgetValue = 'embedPin'
        } else if (isBoardWidget) {
          // ephoxEmbedIri のパスに board 情報 があれば BoardWidget
          pinterestWidgetValue = 'embedBoard'
        } else {
          // pin も board 情報もない場合は ProfileWidget
          pinterestWidgetValue = 'embedUser'
        }

        // 実際に埋め込む HTML
        const embedHtml = `
          <a data-pin-do="${pinterestWidgetValue}" href="${url}" data-pin-width="large" ></a>
          <script src="//assets.pinterest.com/js/pinit.js" async defer></script>
        `

        return embedHtml // eslint-disable-line consistent-return
      }

      const getScriptEmbed = scriptContain => {
        let scriptElement
        if (scriptContain.children.length === 0) {
          if (scriptContain.nodeName.toLowerCase() === 'script') {
            scriptElement = scriptContain
          }
        } else {
          scriptElement = _.find(scriptContain.children, child => _.isEqual(child.nodeName.toLowerCase(), 'script'))
        }
        // Embed code does not define `type` in script
        scriptElement.type = 'text/javascript'
        scriptElement.async = true
        scriptElement.defer = true
        return scriptElement
      }

      editor.on('NodeChange', event => {
        const instagramEmbed = document.querySelector('blockquote.instagram-media')
        if (!_.isNil(instagramEmbed)) {
          // Fetch blockquote & script tag, put it to iframe to preview on editor
          const scriptElement = getScriptEmbed(instagramEmbed.nextElementSibling)
          const embedHtml = instagramEmbed.outerHTML + scriptElement.outerHTML

          // Remove script element cause already convert to iframe
          scriptElement.remove()

          replaceWithIframeEmbed(instagramEmbed, embedHtml)
        }
      })

      editor.addButton('snsEmbedTwitter', {
        icon: 'im-sns-embed-twitter',
        tooltip: 'Twitter ツイートの埋め込み',
        onclick() {
          let selectedNode = editor.selection.getNode()

          if (selectedNode.nodeName.toLowerCase() !== 'a') {
            selectedNode = _.find(selectedNode.children, childNode => childNode.nodeName.toLowerCase() === 'a')
          }

          const linkUrl = selectedNode.href

          oembedApi
            .getEmbedHtml({
              url: linkUrl,
            })
            .then(response => {
              logger.debug('get embed html response', response)

              const embedHtml = _.get(response, 'data.html')

              replaceWithIframeEmbed(selectedNode, embedHtml)
            })
            .catch(error => {
              logger.error('get embed html error', error)
            })
        },
        onPostRender() {
          editor.on('NodeChange', event => {
            const isLink = event.element.nodeName.toLowerCase() === 'a'

            const linkUrl = event.element.href

            const isTwitter = /htt(p|ps):\/\/twitter/.test(linkUrl)

            this.disabled(!isLink || !isTwitter)
          })
        },
      })

      const openArticleInternalLinkDialog = () => {
        let articleId
        editor.windowManager.open({
          title: '内部リンク挿入',
          classes: 'article-internal-link',
          width: 447,
          height: 210,
          body: [
            {
              type: 'label',
              for: 'article-id',
              text: '記事ID',
              classes: 'article-internal-link__search-lable',
            },
            {
              type: 'container',
              layout: 'flex',
              direction: 'row',
              align: 'center',
              spacing: 6,
              classes: 'article-internal-link__search-input-container',
              items: [
                {
                  type: 'textbox',
                  name: 'article-id',
                  width: 191,
                  classes: 'article-internal-link__search-input',
                  onKeyup(event) {
                    articleId = event.target.value
                  },
                },
              ],
            },
            {
              type: 'container',
              layout: 'flex',
              direction: 'row',
              align: 'center',
              spacing: 6,
              id: 'article-internal-link-search-result-container',
              classes: 'article-internal-link__search-result-container',
            },
          ],
          onSubmit(e) {
            const articleInSertContent = `
              <div class="article-internal-link" contenteditable="false" data-article-id="${articleId}" style="display: flex; align-items: center; margin: 20px 2px; padding: 12px; border: 2px solid #d9d9d9; border-radius: 16px; height: 84px; overflow: hidden; position: relative;">
                <a href="/articles/${articleId}" style=" position: absolute; top: 0; left: 0; width: 100%; height: 100%; color: transparent; overflow: hidden; -webkit-user-select: none; user-select: none; text-decoration: none !important;" class="article-internal-link-title">
                  記事の情報受け取るため、一度記事を保存してください
                </a>
                <div style="width: 60px; height: 60px;">
                  <img style="max-height: 100%; width: 100%; height: 100%;" src=""/>
                </div>
                <div class="article-internal-link-title" style="flex: 1; margin: 0 0 0 8px; font-size: 14px; line-height: 24px; max-height: 48px; overflow: hidden; text-overflow: ellipsis; white-space: normal; word-break: break-all; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;">
                </div>
              </div>
              <p></p>
            `
            editor.insertContent(articleInSertContent)
          },
        })
      }

      editor.addMenuItem('downloadimage', {
        text: 'ダウンロード',
        context: 'image',
        disabled: true,
        onclick() {
          const selectedNode = editor.selection.getNode()

          if (selectedNode && selectedNode.nodeName === 'IMG') {
            const imageUrl = selectedNode.src

            if (imageUrl) {
              let fileName = imageUrl.split('/').pop()

              fetch(imageUrl, { cache: 'no-store' })
                .then(response => {
                  const contentType = response.headers.get('Content-Type') || 'image/png'

                  const extension = contentType.split('/')[1]
                  const knownExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'svg']

                  const currentExtension = fileName.split('.').pop()

                  if (!knownExtensions.includes(currentExtension)) {
                    fileName += `.${extension}`
                  }

                  return response.blob()
                })
                .then(blob => {
                  // Create a downloadable URL
                  const downloadUrl = window.URL.createObjectURL(blob)
                  const link = document.createElement('a')
                  link.href = downloadUrl
                  link.download = fileName
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                })
                .catch(err => {
                  logger.error('Error downloading image:', err)
                })
            } else {
              logger.error('Image URL not found')
            }
          } else {
            logger.error('Selected node is not an image')
          }
        },
        onPostRender() {
          editor.on('NodeChange', event => {
            const isImage = event.element.nodeName.toLowerCase() === 'img'
            this.disabled(!isImage)
          })
        },
      })

      editor.addButton('articleInternalLink', {
        icon: 'link',
        tooltip: 'Article internal Link',
        onClick() {
          openArticleInternalLinkDialog()
        },
        onpostrender(data) {
          editor.on('NodeChange', event => {
            const selectedNode = editor.selection.getNode()
            const isArticleInternalLink =
              selectedNode.nodeName.toLowerCase() === 'div' && /(article-internal-link)/.test(selectedNode.className)

            if (isArticleInternalLink) {
              editor.selection.setCursorLocation(selectedNode)
            }
          })
        },
      })

      editor.addButton('snsEmbedFacebook', {
        icon: 'im-sns-embed-facebook',
        tooltip: 'Facebook 投稿の埋め込み',
        onclick() {
          let selectedNode = editor.selection.getNode()

          if (selectedNode.nodeName.toLowerCase() !== 'a') {
            selectedNode = _.find(selectedNode.children, childNode => childNode.nodeName.toLowerCase() === 'a')
          }

          const linkUrl = selectedNode.href

          oembedApi
            .getEmbedHtml({
              url: linkUrl,
            })
            .then(response => {
              logger.debug('get embed html response', response)

              const embedHtml = _.get(response, 'data.html')

              replaceWithIframeEmbed(selectedNode, embedHtml)
            })
            .catch(error => {
              logger.error('get embed html error', error)
            })
        },
        onpostrender() {
          editor.on('NodeChange', event => {
            const isLink = event.element.nodeName.toLowerCase() === 'a'

            const linkUrl = event.element.href

            const isFacebook = /htt(p|ps):\/\/w{3}\.facebook/.test(linkUrl)

            this.disabled(!isLink || !isFacebook)
          })
        },
      })

      editor.addButton('snsEmbedInstagram', {
        icon: 'im-sns-embed-instagram',
        tooltip: 'Instagram 投稿の埋め込み',
        onclick() {
          let selectedNode = editor.selection.getNode()

          if (selectedNode.nodeName.toLowerCase() !== 'a') {
            selectedNode = _.find(selectedNode.children, childNode => childNode.nodeName.toLowerCase() === 'a')
          }

          const linkUrl = selectedNode.href

          oembedApi
            .getEmbedHtml({
              url: linkUrl,
            })
            .then(response => {
              logger.debug('get embed html response', response)

              const embedHtml = _.get(response, 'data.html')

              replaceWithIframeEmbed(selectedNode, embedHtml)
            })
            .catch(error => {
              logger.error('get embed html error', error)
            })
        },
        onpostrender() {
          editor.on('NodeChange', event => {
            const isLink = event.element.nodeName.toLowerCase() === 'a'

            const linkUrl = event.element.href

            const isInstagram = /htt(p|ps):\/\/w{3}\.instagram/.test(linkUrl)

            this.disabled(!isLink || !isInstagram)
          })
        },
      })

      editor.addButton('snsEmbedPinterest', {
        icon: 'im-sns-embed-pinterest',
        tooltip: 'Pinterest 投稿の埋め込み',
        onclick() {
          let selectedNode = editor.selection.getNode()

          if (selectedNode.nodeName.toLowerCase() !== 'a') {
            selectedNode = _.find(selectedNode.children, childNode => childNode.nodeName.toLowerCase() === 'a')
          }

          const embedHtml = getPinterestEmbedHtml(selectedNode.href)

          if (!_.isEmpty(embedHtml)) {
            replaceWithIframeEmbed(selectedNode, embedHtml)
          }
        },
        onpostrender() {
          editor.on('NodeChange', event => {
            const isLink = event.element.nodeName.toLowerCase() === 'a'

            const linkUrl = event.element.href

            const isPinterest = /htt(p|ps):\/\/w{3}\.pinterest/.test(linkUrl)

            this.disabled(!isLink || !isPinterest)
          })
        },
      })

      // 引用の引用元を設定するボタン
      editor.addButton('cite', {
        icon: 'im-blockquote-cite',
        tooltip: '引用元',
        onclick() {
          editor.execCommand('mceToggleFormat', false, 'cite')
        },
        onpostrender() {
          editor.on('NodeChange', event => {
            logger.debug('TinyMCE events (NodeChange) - cite button', event.element, event.parents)

            const isCite = event.element.nodeName.toLowerCase() === 'cite'
            const parentBlockquote = _.find(event.parents, parent => parent.nodeName.toLowerCase() === 'blockquote')

            // 引用ブロックの中でのみ引用元を設定できる
            if (isCite && _.isNil(parentBlockquote)) {
              editor.execCommand('mceToggleFormat', false, 'cite')
              return
            }

            // 引用ブロック内で引用元ではない部分を選択した場合
            if (!_.isNil(parentBlockquote) && !isCite) {
              const cites = _.filter(parentBlockquote.children, child => child.nodeName.toLowerCase() === 'cite')
              // すでに引用元が設定されていた場合
              if (_.size(cites) > 0) {
                this.active(false)
                this.disabled(true)
                return
              }
            }

            this.disabled(_.isNil(parentBlockquote))
            this.active(isCite)
          })
        },
      })

      const linkButtonAccentPatterns = [
        {
          class: 'button--accent-1',
          icon: 'im-link-button-accent-1',
          text: '#022458',
        },
        {
          class: 'button--accent-2',
          icon: 'im-link-button-accent-2',
          text: '#DA789F',
        },
        {
          class: 'button--accent-3',
          icon: 'im-link-button-accent-3',
          text: '#66A079',
        },
        {
          class: 'button--accent-4',
          icon: 'im-link-button-accent-4',
          text: '#96C7E6',
        },
        {
          class: 'button--accent-5',
          icon: 'im-link-button-accent-5',
          text: '#CA0B27',
        },
        {
          class: 'button--accent-6',
          icon: 'im-link-button-accent-6',
          text: '#AAAAAA',
        },
        {
          class: 'button--accent-7',
          icon: 'im-link-button-accent-7',
          text: '#FFCC1A',
        },
        {
          class: 'button--accent-8',
          icon: 'im-link-button-accent-8',
          text: '#6FBBBC',
        },
        {
          class: 'button--accent-9',
          icon: 'im-link-button-accent-9',
          text: '#8D784D',
        },
        {
          class: 'button--accent-10',
          icon: 'im-link-button-accent-10',
          text: '#C8358D',
        },
        {
          class: 'button--accent-11',
          icon: 'im-link-button-accent-11',
          text: '#3BC985',
        },
        {
          class: 'button--accent-12',
          icon: 'im-link-button-accent-12',
          text: '#FAB650',
        },
        {
          class: 'button--accent-13',
          icon: 'im-link-button-accent-13',
          text: '#C72424',
        },
      ]

      const linkButtonAccentClasses = _.map(linkButtonAccentPatterns, 'class')

      const linkButtonAccentMenu = []
      const linkButtonAccentMenuItems = []

      // リンクボタン（主にスポコンで使う）を設定するボタン
      editor.addButton('linkButton', {
        icon: 'im-link-button',
        tooltip: 'リンクボタン',
        onclick() {
          editor.execCommand('mceToggleFormat', false, 'blockButton')

          const selectedNode = editor.selection.getNode()

          if (!selectedNode.classList.contains('button')) {
            selectedNode.classList.remove(...linkButtonAccentClasses)
          }
        },
        onpostrender() {
          editor.on('NodeChange', event => {
            const isLink = event.element.nodeName.toLowerCase() === 'a'

            const isBlockButton =
              event.element.classList.contains('button') && event.element.classList.contains('button--block')

            if (isBlockButton && _.isNil(isLink)) {
              editor.execCommand('mceToggleFormat', false, 'blockButton')
              return
            }

            this.disabled(!isLink)
            this.active(isBlockButton)
          })
        },
      })

      // リンクボタンの色パターンを選択するメニューのアイテム
      _.each(linkButtonAccentPatterns, pattern => {
        linkButtonAccentMenu.push({
          icon: pattern.icon,
          text: pattern.text,
          onclick() {
            if (!_.includes(linkButtonAccentMenuItems, this)) {
              linkButtonAccentMenuItems.push(this)
            }

            const selectedNode = editor.selection.getNode()

            if (selectedNode.classList.contains(pattern.class)) {
              selectedNode.classList.remove(pattern.class)
              this.active(false)
            } else {
              selectedNode.classList.remove(...linkButtonAccentClasses)
              _.each(linkButtonAccentMenuItems, menuItem => {
                menuItem.active(false)
              })

              selectedNode.classList.add(pattern.class)
              this.active(true)
            }
          },
          onpostrender() {
            editor.on('NodeChange', event => {
              const isSelected = event.element.classList.contains(pattern.class)
              this.active(isSelected)
            })
          },
        })
      })

      // リンクボタンの色パターンを選択するメニュー
      editor.addButton('linkButtonAccent', {
        type: 'menubutton',
        icon: 'im-link-button-accent',
        tooltip: '色パターン',
        menu: linkButtonAccentMenu,
        onpostrender() {
          editor.on('NodeChange', event => {
            const isLink = event.element.nodeName.toLowerCase() === 'a'

            const isBlockButton =
              event.element.classList.contains('button') && event.element.classList.contains('button--block')

            this.disabled(!isLink || !isBlockButton)
          })
        },
      })
    }
    this.config.init_instance_callback = this.handleInit
  }

  componentDidMount() {
    const tinyMceScriptUrl = `${process.env.REACT_APP_TRILL_ASSETS_URL}/tinymce/js/tinymce/tinymce.min.js`

    let isTinyMceScriptLoaded = false

    _.each(document.getElementsByTagName('script'), script => {
      if (script.src === tinyMceScriptUrl) {
        isTinyMceScriptLoaded = true
      }
    })

    if (isTinyMceScriptLoaded) {
      this.setState({
        isTinyMceScriptLoaded: true,
      })
    } else {
      const tinyMceScript = document.createElement('script')
      tinyMceScript.setAttribute('src', tinyMceScriptUrl)

      document.body.appendChild(tinyMceScript)

      tinyMceScript.onload = () => {
        this.setState({
          isTinyMceScriptLoaded: true,
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.description, nextProps.description)) {
      // 初回のみ呼ばれる
      this.initialDescription = nextProps.description
      this.description = nextProps.description

      const editorContent = this.editor ? this.editor.getContent() : ''
      if (!_.isEqual(this.description, editorContent)) {
        this.editor.setContent(this.description)
      }
    }
  }

  /**
   * constructor の後に呼ばれる editor の初期化ハンドラ
   */
  handleInit = editor => {
    this.editor = editor
    this.editor.undoManager.clear()
    this.editor.setContent(this.description)
    _.invoke(this.props, 'onInit', { ...this.props, editor })
  }

  handleChange = (description, error) => {
    _.invoke(this.props, 'onChange', { ...this.props, description, error })
  }

  handleByteLengthWarning = display => {
    if (this.isWarningByteLength !== display) {
      this.isWarningByteLength = display
      _.invoke(this.props, 'fireByteLengthWarning', display)
    }
  }

  /**
   * 親要素から対象のノードを取得
   */
  findParentNode = (node, targetNodeName, depth) => {
    const nodeName = node.nodeName.toLowerCase()
    // 検索したいノードと検索中のノードの名前が一致した場合
    if (_.isEqual(nodeName, targetNodeName)) {
      return node
    }

    const parentNode = node.parentNode
    const nextDepth = depth - 1

    // 指定階層分検索した場合 or 親ノードがない場合
    if (nextDepth < 1 || _.isNil(parentNode)) {
      return null
    }

    return this.findParentNode(parentNode, targetNodeName, nextDepth)
  }

  render() {
    return (
      <div className="ArticleDescriptionEditor">
        <Dimmer active={this.state.isBusy || !this.state.isTinyMceScriptLoaded} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {this.state.isTinyMceScriptLoaded && <TinyMce className="trill-description" config={this.config} />}
      </div>
    )
  }
}

ArticleDescriptionEditor.propTypes = propTypes
ArticleDescriptionEditor.defaultProps = defaultProps

export default ArticleDescriptionEditor
