import React, { Component } from 'react'
import {
  Dimmer,
  Loader,
  Header,
  Segment,
  Divider,
  Icon,
  Label,
  Menu,
  Button,
  Checkbox,
  Message,
  Image,
  Grid,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import { FormattedDate, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import moment from 'moment'

import ArticleDescriptionEditor from '../../components/ArticleDescriptionEditor'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import MediaInput from '../../components/MediaInput'
import DatetimeTextPicker from '../../components/DatetimeTextPicker'

import './Article.css'

const PUBLISH_DATETIME_LABEL_FORMAT = 'llll'

function getResetPublishDatetime() {
  const initialMoment = moment().add(1, 'day')
  const dateTimeMoment = initialMoment.minutes() >= 35 ? initialMoment.add(1, 'hour') : initialMoment
  return moment(`${dateTimeMoment.format('YYYY-MM-DD HH')}:35:00`, 'YYYY-MM-DD HH:mm:ss')
}

class Article extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShowSideBar: false,
      isShowImageDetail: false,
    }
  }

  handleShowSidebar = () => {
    this.setState({ isShowSideBar: true })
  }

  handleHideSidebar = () => {
    this.setState({ isShowSideBar: false })
  }

  /**
   * Show/Hide image detail section (copyright,...)
   */
  handleToggleImageDetailVisibility = () => {
    this.setState({
      isShowImageDetail: !this.state.isShowImageDetail,
    })
  }

  /**
   * Render form fields for QR code and preview
   */
  renderPreviewSection = () => {
    return (
      <div>
        <Header as="h3" content="プレビュー" />

        <Segment padded>
          <Label attached="top left">プレビュー</Label>

          <div className="article-information__preview-button">
            {this.props.previewUrl && (
              <Button
                circular
                fluid
                icon="eye"
                color="blue"
                as="a"
                href={this.props.previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                content="プレビュー"
                labelPosition="left"
                disabled={!this.props.isPreviewed}
              />
            )}

            <Divider hidden />

            {this.props.qrcode && <Image src={this.props.qrcode} fluid />}
          </div>
        </Segment>
      </div>
    )
  }

  /**
   * Render form fields for article media related data like thumbnail, cover, copyright
   */
  renderMediaSection = () => {
    return (
      <div>
        <Header as="h3" content="画像・動画" />

        <Segment.Group>
          <Segment>
            <Form.Field error={!_.isNil(this.props.thumbnailImageError)} style={{ marginBottom: 0 }}>
              <label>{this.props.isCoverImageActive ? 'サムネイル画像' : 'サムネイル・カバー画像'}</label>

              <MediaInput
                isDragDrop={true}
                mediaUrl={this.props.thumbnailImageUrlInputValue}
                minWidth={640}
                minHeight={396}
                onChange={this.props.handleThumbnailImageInputChange}
              />

              <Input
                className="isHidden"
                name="thumbnail.image.url"
                placeholder="サムネイル画像を選択してください"
                readOnly
                value={this.props.thumbnailImageUrlInputValue}
              />
            </Form.Field>
          </Segment>

          <Segment>
            {/* Use className=`field` for same CSS as a field */}
            <div className="field">
              <label style={{ cursor: 'pointer', marginBottom: 0 }} onClick={this.handleToggleImageDetailVisibility}>
                <Icon name={this.state.isShowImageDetail ? 'caret down' : 'caret right'} />
                サムネイルの詳細設定
              </label>
            </div>
          </Segment>

          <Segment
            className={classNames({
              isHidden: !this.state.isShowImageDetail || !this.props.thumbnailImageUrlInputValue,
            })}
          >
            <Form.Input name="thumbnail.image.copyright.title" label="出典元" placeholder="出典元を入力してください" />

            <Form.Input
              name="thumbnail.image.copyright.url"
              label="出典元の URL"
              placeholder="出典元の URL を入力してください"
              validations="isUrl"
              validationErrors={{
                isUrl: '無効な URL です',
              }}
              errorLabel={<Label color="red" pointing />}
            />
          </Segment>

          <Segment
            className={classNames({
              isHidden: !this.state.isShowImageDetail,
            })}
          >
            <Checkbox
              toggle
              label="カバー画像を別の画像に設定"
              checked={this.props.isCoverImageActive}
              onChange={this.props.handleCoverImageToggleChange}
            />
          </Segment>

          <Segment
            className={classNames({
              isHidden: !this.state.isShowImageDetail || !this.props.isCoverImageActive,
            })}
          >
            <Form.Field error={!_.isNil(this.props.coverImageError)} style={{ marginBottom: 0 }}>
              <label>カバー画像</label>

              <MediaInput
                isDragDrop={true}
                mediaUrl={this.props.coverImageUrlInputValue}
                minWidth={640}
                minHeight={396}
                onChange={this.props.handleCoverImageInputChange}
              />

              <Input
                className="isHidden"
                name="cover.image.url"
                placeholder="カバー画像を選択してください"
                readOnly
                value={this.props.coverImageUrlInputValue}
              />
            </Form.Field>

            <div
              className={classNames({
                isHidden: !this.props.coverImageUrlInputValue,
              })}
              style={{ marginTop: '1rem' }}
            >
              <Form.Input name="cover.image.copyright.title" label="出典元" placeholder="出典元を入力してください" />

              <Form.Input
                name="cover.image.copyright.url"
                label="出典元の URL"
                placeholder="出典元の URL を入力してください"
                validations="isUrl"
                validationErrors={{
                  isUrl: '無効な URL です',
                }}
                errorLabel={<Label color="red" pointing />}
              />
            </div>
          </Segment>
        </Segment.Group>
      </div>
    )
  }

  /**
   * Render form fields for article detail related data like publish datetime
   */
  renderDetailSection = () => {
    return (
      <div>
        <Header as="h3" content="記事情報" />

        <Form.Field style={{ position: 'relative' }}>
          <label>公開日時</label>
          <Button
            circular
            basic
            color="blue"
            type="button"
            size="mini"
            onClick={this.props.handlePublishDatetimeSetToday}
            style={{ position: 'absolute', top: '-0.5rem', right: 0 }}
          >
            今日
          </Button>

          <DatetimeTextPicker
            name="publishDatetime"
            value={this.props.publishDatetime}
            format={PUBLISH_DATETIME_LABEL_FORMAT}
            resetValue={getResetPublishDatetime()}
            placeholder="公開日時を指定"
            invalidDatetimeMessage="公開日時の形式が不正です。yyyymmdd hhmmの形式で入力してください。例）20250101 1435"
            invalidDatetimeMessagePosition="top left"
            onChange={this.props.handlePublishDatetimeChange}
            onInvalid={this.props.handlePublishDatetimeInvalid}
          />
        </Form.Field>
      </div>
    )
  }

  render() {
    return (
      <div className="article">
        <Form
          className="article__form"
          ref={this.props.setRef}
          noValidate
          onChange={this.props.handleFormChange}
          onValid={this.props.handleFormValid}
          onInvalid={this.props.handleFormInvalid}
          onValidSubmit={this.props.handleFormValidSubmit}
        >
          <div className="article__main custom-scroll-bar">
            <Segment className="article__main__header">
              <Grid>
                <Grid.Column floated="left" width={14}>
                  <Header as="h1">
                    <Icon name="edit" />
                    <Header.Content>記事の編集</Header.Content>
                    <Label color="teal" size="large" image>
                      記事ID
                      <Label.Detail>{this.props.articleId}</Label.Detail>
                    </Label>
                  </Header>
                </Grid.Column>
                <Grid.Column floated="right" width={2}>
                  <Button
                    type="button"
                    icon="content"
                    floated="right"
                    className="article__main__header__menu-button"
                    onClick={this.handleShowSidebar}
                  />
                  <Button
                    primary
                    circular
                    type="submit"
                    content="記事を更新"
                    icon="save"
                    floated="right"
                    labelPosition="left"
                    className="article__main__header__submit-button"
                    disabled={
                      !this.props.isFormValid ||
                      this.props.isDuringSubmit ||
                      !this.props.isFormModified ||
                      this.props.descriptionError
                    }
                    onClick={this.props.onUpdateButton}
                  />
                </Grid.Column>
              </Grid>
            </Segment>

            <Dimmer active={this.props.isBusy} inverted page={this.props.isDuringSubmit}>
              <Loader>読み込み中</Loader>
            </Dimmer>

            <div className="article__main__sub-header">
              <Button
                primary
                circular
                type="submit"
                content="記事を更新"
                icon="save"
                labelPosition="left"
                className="article__main__sub-header__submit-button"
                disabled={
                  !this.props.isFormValid ||
                  this.props.isDuringSubmit ||
                  !this.props.isFormModified ||
                  this.props.descriptionError
                }
                onClick={this.props.onUpdateButton}
              />
            </div>

            <div className="article__main__content">
              <Form.Input name="title" label="タイトル" placeholder="タイトルを入力してください" required />

              <Form.TextArea
                name="summary"
                label="概要"
                placeholder="概要を入力してください"
                autoHeight
                rows={1}
                required
              />

              <Form.Field required error={!_.isNil(this.props.descriptionError)}>
                <label>本文</label>

                <Segment className={classNames({ error: this.props.descriptionError })}>
                  {this.props.isArticleInitialized && (
                    <ArticleDescriptionEditor
                      description={this.props.descriptionInputValue}
                      onInit={this.props.handleDescriptionEditorInit}
                      onChange={this.props.handleDescriptionEditorChange}
                      fireByteLengthWarning={this.props.handleEditorWarning}
                    />
                  )}

                  {!_.isNil(this.props.descriptionError) && (
                    <Label color="red" attached="top right">
                      {this.props.descriptionError.message}
                    </Label>
                  )}
                </Segment>

                <Input
                  className="isHidden"
                  name="description"
                  placeholder="本文を入力してください"
                  required
                  readOnly
                  value={this.props.descriptionInputValue}
                />
              </Form.Field>

              <ApiErrorMessage error={this.props.apiError} />

              <Message as={Message} warning animation="overlay" direction="bottom" visible={this.props.editorWarning}>
                更新ボタンを押して記事を保存してください。
              </Message>
            </div>
          </div>

          <div className={`article__sidebar${this.state.isShowSideBar ? ' article__sidebar--visible' : ''}`}>
            <Header as="h2" content="情報" />

            <Header as="h4" content="最終更新" color="grey" />
            {this.props.updatedAt && (
              <p style={{ fontSize: '1.25rem' }}>
                <FormattedDate
                  value={this.props.updatedAt}
                  day="numeric"
                  month="long"
                  year="numeric"
                  weekday="narrow"
                />
                &nbsp;
                <FormattedTime value={this.props.updatedAt} hour="numeric" minute="numeric" second="numeric" />
              </p>
            )}
            <Divider hidden />

            <Button
              fluid
              type="button"
              icon="external"
              color="blue"
              onClick={this.props.handleOpenAdminSiteButtonClick}
              content="TRILL記事"
              labelPosition="right"
              disabled={!this.props.isFormValid || this.props.isDuringSubmit || this.props.descriptionError}
            />
            <Divider hidden />

            <Divider />
            <Menu pointing secondary color="blue" className="custom-scroll-bar">
              <Menu.Item
                content="プレビュー"
                name="preview"
                onClick={this.props.handleSidebarMenuItemClick}
                active={_.isEqual(this.props.viewSidebarStatus, 'preview')}
              />
              <Menu.Item
                content="記事情報"
                name="meta"
                onClick={this.props.handleSidebarMenuItemClick}
                active={_.isEqual(this.props.viewSidebarStatus, 'meta')}
              />
            </Menu>

            <Divider hidden />

            <div
              style={{
                display: _.isEqual(this.props.viewSidebarStatus, 'preview') ? '' : 'none',
              }}
              className="article__sidebar__menu__tab custom-scroll-bar"
            >
              {this.renderPreviewSection()}
            </div>

            <div
              style={{
                display: _.isEqual(this.props.viewSidebarStatus, 'meta') ? '' : 'none',
              }}
              className="article__sidebar__menu__tab custom-scroll-bar"
            >
              {this.renderMediaSection()}

              <Divider hidden />

              {this.renderDetailSection()}
            </div>
          </div>

          <div
            className={`article__sidebar-backdrop${
              this.state.isShowSideBar ? ' article__sidebar-backdrop--visible' : ''
            }`}
            onClick={this.handleHideSidebar}
          >
            <Button
              type="button"
              icon="close"
              className="article__sidebar-backdrop__close-button"
              onClick={this.handleHideSidebar}
            />
          </div>
        </Form>
      </div>
    )
  }
}

export default Article
