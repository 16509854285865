import React from 'react'

import _ from 'lodash'
import moment from 'moment'
import QRCode from 'qrcode'

import TrillDescription from 'trill-description'
import { ApiClient, OutsourcedArticleApi } from 'trill-api-admin-client'

import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'

import Article from './Article'

const logger = LogLevel.getLogger('ArticleContainer')

// TRILL API クライアントの初期化
const apiClient = ApiClient.instance
apiClient.basePath = process.env.REACT_APP_TRILL_API_BASE_PATH

const outsourcedArticleApi = new OutsourcedArticleApi()
let getArticle
let patchArticle

function convertTextToDatetime(publishDatetime) {
  return _.isEmpty(publishDatetime) ? null : moment(publishDatetime)
}

export default class ArticleContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      articleId: null,

      isFormInitialized: false,
      isFormValid: false,
      isFormModified: false,
      isOpenAdminSite: false,
      isPreviewed: true,
      isArticleInitialized: false,

      descriptionInputValue: '',
      descriptionError: null,
      editorWarning: false,

      thumbnailImageUrlInputValue: '',
      thumbnailImageError: null,

      isCoverImageActive: false,
      coverImageUrlInputValue: '',
      coverImageError: null,

      publishDatetime: null,

      updatedAt: '',

      viewSidebarStatus: 'preview',

      isDuringSubmit: false,
      isBusy: false,
      apiError: null,
    }
  }

  apiClientAuthentication = apiClient.authentications['OutsourcedArticleTool'] // eslint-disable-line dot-notation

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  async componentDidMount() {
    const articleId = this.props.routeParams.id
    const token = _.get(this.props, 'location.query.token')
    this.apiClientAuthentication.apiKey = token

    try {
      await this.retrieveArticle(articleId)

      const generateQR = async text => {
        let dataURL = ''
        try {
          dataURL = await QRCode.toDataURL(text, { margin: 0 })
          return dataURL
        } catch (err) {
          logger.debug('generateQR error', err)
        }
        return dataURL
      }

      const adminSiteLink = `${process.env.REACT_APP_TRILL_ADMIN_URL}/article/${articleId}?fromOutsourcedArticle=true`
      const previewUrl = `${process.env.REACT_APP_TRILL_WEB_URL}/articles-preview/${articleId}?token=${token}`
      const qrcode = await generateQR(previewUrl)

      this.setState({
        articleId,
        isFormInitialized: true,
        isArticleInitialized: true,
        adminSiteLink,
        previewUrl,
        qrcode,
      })
    } catch (error) {
      this.props.router.replace('/500')
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextArticleId = nextProps.routeParams.id
    const token = _.get(nextProps, 'location.query.token')
    this.apiClientAuthentication.apiKey = token

    if (!_.isNil(nextArticleId) && nextArticleId !== this.state.articleId) {
      this.setState({ articleId: nextArticleId })

      this.retrieveArticle(nextArticleId)
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getArticle)) {
      getArticle.cancel()
    }
    if (!_.isNil(patchArticle)) {
      patchArticle.cancel()
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    const partialState = { isFormModified: false }

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(currentValues, (currentValue, inputName) => {
      const initialValue = this.initialFormValues[inputName]
      if (_.defaultTo(initialValue, '') !== _.defaultTo(currentValue, '')) {
        partialState.isFormModified = true
      }
    })

    // フォームの値に (変更あり から 変更なし) または (変更なし から 変更あり) に変わったタイミングのみ state を変更
    if (!_.isEqual(this.state.isFormModified, partialState.isFormModified)) {
      this.setState(partialState)
    }
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState(
      {
        isBusy: true,
        isDuringSubmit: true,
        apiError: null,
        isFormInitialized: false,
      },
      async () => {
        try {
          const requestParameters = await this.getRequestParameters(submitFormData)
          patchArticle = CancelablePromise(this.sendArticleData(requestParameters))
          await patchArticle.promise
          await this.retrieveArticle(this.state.articleId)

          const { isOpenAdminSite, adminSiteLink } = this.state
          if (isOpenAdminSite && adminSiteLink) {
            window.open(adminSiteLink, '_blank', 'noopener,noreferrer')
          }

          this.setState({
            isFormModified: false,
            isDuringSubmit: false,
            isBusy: false,
            isFormInitialized: true,
            isOpenAdminSite: false,
            editorWarning: false,
          })
        } catch (error) {
          if (error.isCanceled) {
            return
          }

          logger.error('update article article error ', error)

          this.setState({
            isBusy: false,
            isDuringSubmit: false,
            apiError: error,
            isFormInitialized: true,
            isOpenAdminSite: false,
          })
        }
      },
    )
  }

  /**
   * 記事本文エディターの初期化時のハンドラ
   */
  handleDescriptionEditorInit = ({ editor }) => {
    this.descriptionEditor = editor
  }

  /**
   * 記事本文エディターの内容が変わったときのハンドラ
   */
  handleDescriptionEditorChange = ({ description, error }) => {
    logger.debug('handle description editor change', description)

    const partialState = {
      descriptionInputValue: _.defaultTo(description, ''),
      descriptionError: error,
    }

    this.setState(partialState)
  }

  handleEditorWarning = display => {
    this.setState({ editorWarning: display })
  }

  onUpdateButton = () => {
    this.form.formsyForm.submit()
  }

  handleOpenAdminSiteButtonClick = () => {
    this.setState(
      {
        isOpenAdminSite: true,
      },
      () => this.form.formsyForm.submit(),
    )
  }

  handleSidebarMenuItemClick = (event, { name }) => {
    this.setState({ viewSidebarStatus: name })
  }

  handleThumbnailImageInputChange = (event, { mediaUrl, error }) => {
    const value = _.defaultTo(mediaUrl, '')

    this.setState({
      thumbnailImageUrlInputValue: value,
      thumbnailImageError: error,
    })
  }

  handleCoverImageToggleChange = (event, { checked }) => {
    this.setState({ isCoverImageActive: checked })
  }

  handleCoverImageInputChange = (event, { mediaUrl, error }) => {
    const value = _.defaultTo(mediaUrl, '')

    this.setState({
      coverImageUrlInputValue: value,
      coverImageError: error,
    })
  }

  /**
   * Handler for publish datetime (by input or by pick) changing event
   */
  handlePublishDatetimeChange = (event, { value }) => {
    this.setState({ publishDatetime: value })
  }

  /**
   * Handler for 'Set Today' button clicking event
   */
  handlePublishDatetimeSetToday = () => {
    const getNextTime = () => {
      const today = moment()
      const hour = today.minutes() >= 35 ? today.hours() + 1 : today.hours()

      return `${hour}:35:00`
    }

    const date = moment().format('YYYY-MM-DD')
    const time = _.isEmpty(this.state.publishDatetime)
      ? getNextTime()
      : moment(this.state.publishDatetime).format('HH:mm:ss')

    const publishDatetime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss')
    this.setState({ publishDatetime })
  }

  /**
   * API にデータを送信
   */
  sendArticleData(submitArticleData) {
    const { articleId, isOpenAdminSite } = this.state
    return new Promise((resolve, reject) => {
      if (_.isEmpty(submitArticleData) && !isOpenAdminSite) {
        const error = new Error('Missing required article data')
        reject(error)
      }

      logger.debug(`update article #${articleId}`, { submitArticleData })

      const outsourcedArticleUpdateValues = submitArticleData
      return outsourcedArticleApi
        .patchOutsourcedArticle(articleId, { outsourcedArticleUpdateValues })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * 記事取得
   * @param {string} articleId - 記事 id
   */
  async retrieveArticle(articleId) {
    logger.debug(`retrieve article #${articleId}`)

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getArticle = CancelablePromise(outsourcedArticleApi.getOutsourcedArticle(articleId))
    try {
      const articleResponse = await getArticle.promise

      logger.debug(`retrieved article #${articleId} data`, { articleResponse })

      // 記事詳細データ
      const articleData = articleResponse.data

      this.initializeFormValues(articleData)
      this.form.reset(this.initialFormValues)

      const { description, publishDatetime } = this.initialFormValues

      const thumbnailImageUrl = this.initialFormValues['thumbnail.image.url']
      const thumbnailImageUrlInputValue = thumbnailImageUrl

      const coverImageUrl = this.initialFormValues['cover.image.url']
      const coverImageUrlInputValue = coverImageUrl
      const isCoverImageActive =
        !(_.isNil(coverImageUrl) || coverImageUrl === '') && coverImageUrl !== thumbnailImageUrl

      this.setState({
        descriptionInputValue: description,
        thumbnailImageUrlInputValue,
        isCoverImageActive,
        coverImageUrlInputValue,
        publishDatetime: convertTextToDatetime(publishDatetime),
        updatedAt: _.get(articleData, 'updatedAt'),
        isBusy: false,
      })
    } catch (errors) {
      getArticle.cancel()

      if (errors.isCanceled) {
        return
      }

      const { statusCode } = errors.response

      switch (statusCode) {
        case 401:
        case 403:
        case 404:
          this.props.router.replace(`/${statusCode}`)
          break
        default:
          logger.error(`retrieve article #${articleId} error`, errors.message)

          this.setState({
            isBusy: false,
            apiError: errors,
          })

          throw errors
      }
    }
  }

  /**
   * フォームの初期化
   * @param {Object} articleData - 記事データ
   */
  initializeFormValues(articleData) {
    this.initialFormValues.title = articleData.title
    this.initialFormValues.summary = articleData.summary
    this.initialFormValues.description = _.defaultTo(articleData.description, '')

    this.initialFormValues['thumbnail.image.url'] = _.get(articleData, 'thumbnail.image.url', '')
    this.initialFormValues['thumbnail.image.copyright.title'] = _.get(
      articleData,
      'thumbnail.image.copyright.title',
      '',
    )
    this.initialFormValues['thumbnail.image.copyright.url'] = _.get(articleData, 'thumbnail.image.copyright.url', '')

    this.initialFormValues['cover.image.url'] = _.get(articleData, 'cover.image.url', '')
    this.initialFormValues['cover.image.copyright.title'] = _.get(articleData, 'cover.image.copyright.title', '')
    this.initialFormValues['cover.image.copyright.url'] = _.get(articleData, 'cover.image.copyright.url', '')

    const publishDatetime = articleData.publishDatetime
    this.initialFormValues.publishDatetime = _.isEmpty(publishDatetime) ? '' : moment(publishDatetime).format()

    const isPreviewed = !(
      _.isEmpty(this.initialFormValues.title) ||
      _.isEmpty(this.initialFormValues.summary) ||
      _.isEmpty(this.initialFormValues.description)
    )
    this.setState({ isPreviewed })

    logger.debug('initial form values', this.initialFormValues, articleData)
  }

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData =>
    new Promise((resolve, reject) => {
      // 差分を取得する関数
      const difference = (object, base) => {
        const changes = (_object, _base) =>
          _.transform(_object, (result, value, key) => {
            if (!_.isEqual(value, _base[key])) {
              result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
            }
          })
        return changes(object, base)
      }

      // 変更前のフォームの値
      const { initialFormValues } = this
      const initialData = {}
      initialData.title = initialFormValues.title
      initialData.summary = initialFormValues.summary
      initialData.description = _.defaultTo(initialFormValues.description, '')

      _.set(initialData, 'thumbnail.image.url', _.get(initialFormValues, 'thumbnail.image.url', ''))
      _.set(initialData, 'thumbnail.image.copyright.title', _.get(initialFormValues, 'thumbnail.image.copyright.title'))
      _.set(initialData, 'thumbnail.image.copyright.url', _.get(initialFormValues, 'thumbnail.image.copyright.url'))

      _.set(initialData, 'cover.image.url', _.get(initialFormValues, 'cover.image.url', ''))
      _.set(initialData, 'cover.image.copyright.title', _.get(initialFormValues, 'cover.image.copyright.title'))
      _.set(initialData, 'cover.image.copyright.url', _.get(initialFormValues, 'cover.image.copyright.url'))

      initialData.publishDatetime = _.get(initialFormValues, 'publishDatetime', '')

      TrillDescription.parse(this.descriptionEditor.getContent())
        .then(trillDescription => {
          _.unset(submitFormData, 'options')

          const requestParameters = difference(submitFormData, initialData)

          if (_.has(requestParameters, 'description')) {
            requestParameters.description = trillDescription.toHtmlString()
          }

          if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.title'), '')) {
            _.set(requestParameters, 'thumbnail.image.copyright.title', ' ')
          }

          if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.url'), '')) {
            _.set(requestParameters, 'thumbnail.image.copyright.url', ' ')
          }

          if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.title'), '')) {
            _.set(requestParameters, 'cover.image.copyright.title', ' ')
          }

          if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.url'), '')) {
            _.set(requestParameters, 'cover.image.copyright.url', ' ')
          }

          _.merge(requestParameters, {
            thumbnail: {
              video: {
                url: ' ',
                copyright: {
                  title: ' ',
                  url: ' ',
                },
              },
            },
            cover: {
              video: {
                url: ' ',
                copyright: {
                  title: ' ',
                  url: ' ',
                },
              },
            },
          })

          // 画像のURLからリクエストを送信する前に、クエリパラメータ（例：?temp）を削除してください。
          const handleRemoveQueryParamsInUrl = key => {
            const url = _.get(requestParameters, key, '')

            if (url.match(/^(http:|https:|).*\?/gm)) {
              _.set(requestParameters, key, url.split('?')[0])
            }
          }
          _.each(['thumbnail', 'cover'], type => {
            _.each(['url', 'copyright.url'], key => handleRemoveQueryParamsInUrl([type, 'image', key].join('.')))
          })

          if (_.has(requestParameters, 'publishDatetime')) {
            // 公開日時の設定が空文字の場合はリセット
            requestParameters.publishDatetime = _.isEmpty(requestParameters.publishDatetime)
              ? ' '
              : moment(requestParameters.publishDatetime).toISOString()
          }

          logger.debug('get request parameters', {
            initialData,
            submitFormData,
            requestParameters,
          })

          resolve(_.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && !_.isArray && _.isEmpty(v)))
        })
        .catch(error => {
          let message
          if (error.message === 'Invalid image display size: {}') {
            message =
              '何らかの理由で画像の表示サイズを取得できませんでした。編集画面の本文内を右クリックしソースコードをクリックしてソースコードをコピーしてTRILL編集部へお伝えください。'
          } else if (
            message === 'Invalid block dom detected: {"type":"tag","name":"figure","attribs":{"class":"image"}}'
          ) {
            message =
              '画像の出典が残っている箇所があります。編集画面の本文内を右クリックしソースコードをクリックしてソースコードをコピーしてTRILL編集部へお伝えください。'
          } else {
            message =
              '記事本文内のHTML構成にエラーがあります。編集画面の本文内を右クリックしソースコードをクリックしてソースコードをコピーしてTRILL編集部へお伝えください。'
            message = `${message} ${error.message}`
          }
          error.message = message
          reject(error)
        })
    })

  render() {
    return (
      <Article
        {...this.state}
        setRef={elem => {
          this.form = elem
        }}
        handleFormChange={this.handleFormChange}
        handleFormValid={this.handleFormValid}
        handleFormInvalid={this.handleFormInvalid}
        handleFormValidSubmit={this.handleFormValidSubmit}
        handleDescriptionEditorInit={this.handleDescriptionEditorInit}
        handleDescriptionEditorChange={this.handleDescriptionEditorChange}
        handleEditorWarning={this.handleEditorWarning}
        onUpdateButton={this.onUpdateButton}
        handleOpenAdminSiteButtonClick={this.handleOpenAdminSiteButtonClick}
        handleSidebarMenuItemClick={this.handleSidebarMenuItemClick}
        handleThumbnailImageInputChange={this.handleThumbnailImageInputChange}
        handleCoverImageToggleChange={this.handleCoverImageToggleChange}
        handleCoverImageInputChange={this.handleCoverImageInputChange}
        handlePublishDatetimeChange={this.handlePublishDatetimeChange}
        handlePublishDatetimeInvalid={this.handleFormInvalid}
        handlePublishDatetimeSetToday={this.handlePublishDatetimeSetToday}
      />
    )
  }
}
